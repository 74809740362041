import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';

function ProposalForm() {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState(null);
  const { token } = useOutletContext();

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const res = await axios.get('http://localhost:5000/clients', {
          headers: {
            'auth-token': token
          }
        });
        setClients(res.data);
      } catch (err) {
        console.error('Erro ao buscar clientes:', err.response?.data || err.message);
      }
    };

    fetchClients();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('userId', selectedClient);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('file_path', file.name); // Envia apenas o nome do arquivo

    try {
      await axios.post('http://localhost:5000/proposals', {
        userId: selectedClient,
        title,
        description,
        file_path: file.name,
        status: 'offered' // Define o status inicial aqui também
      }, {
        headers: {
          'auth-token': token
        }
      });
      alert('Proposta criada com sucesso!');
    } catch (err) {
      console.error('Erro ao criar proposta:', err.response?.data || err.message);
      alert('Erro ao criar proposta.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Criar Proposta</h2>
      <div>
        <label>Cliente</label>
        <select value={selectedClient} onChange={(e) => setSelectedClient(e.target.value)} required>
          <option value="">Selecione um cliente</option>
          {clients.map(client => (
            <option key={client.id} value={client.id}>{client.name} ({client.email})</option>
          ))}
        </select>
      </div>
      <div>
        <label>Título</label>
        <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
      </div>
      <div>
        <label>Descrição</label>
        <textarea value={description} onChange={(e) => setDescription(e.target.value)} required />
      </div>
      <div>
        <label>Arquivo</label>
        <input type="file" onChange={(e) => setFile(e.target.files[0])} required />
      </div>
      <button type="submit">Enviar</button>
    </form>
  );
}

export default ProposalForm;
