import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';

const DashboardLayout = ({ token, user }) => {
  if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <div className="dashboard">
      <Sidebar user={user} />
      <div className="dashboard-content">
        <Outlet context={{ token, user }} />
      </div>
    </div>
  );
};

export default DashboardLayout;
