import React from 'react';
import { Link } from 'react-router-dom';

function Sidebar({ user }) {
  return (
    <div className="sidebar">
      <h2>Dashboard</h2>
      <nav>
        <ul>
          <li><Link to="/dashboard/offered">Propostas Oferecidas</Link></li>
          <li><Link to="/dashboard/accepted">Propostas Aceitas</Link></li>
          <li><Link to="/dashboard/in-progress">Propostas em Finalização</Link></li>
          <li><Link to="/dashboard/completed">Propostas Finalizadas</Link></li>
          {user && user.isAdmin && (
            <>
              <li><Link to="/dashboard/create-proposal">Criar Proposta</Link></li>
              <li><Link to="/dashboard/create-client">Cadastrar Cliente</Link></li>
            </>
          )}
        </ul>
      </nav>
    </div>
  );
}

export default Sidebar;
