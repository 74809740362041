import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PublicLayout from './components/PublicLayout';
import DashboardLayout from './components/DashboardLayout';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import Login from './components/Login';
import DashboardRoutes from './components/DashboardRoutes';
import ScrollToTop from './components/ScrollToTop'; // Importe o ScrollToTop
import './styles/App.css';

function App() {
  const [token, setToken] = useState('');
  const [user, setUser] = useState(null);

  return (
    <Router>
      <ScrollToTop /> {/* Use o ScrollToTop aqui */}
      <Routes>
        <Route element={<PublicLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login setToken={setToken} setUser={setUser} />} />
        </Route>
        <Route element={<DashboardLayout token={token} user={user} />}>
          <Route path="/dashboard/*" element={<DashboardRoutes />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
