import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';

function Proposals({ status }) {
  const { token, user } = useOutletContext();
  const [proposals, setProposals] = useState([]);

  useEffect(() => {
    const fetchProposals = async () => {
      try {
        const res = await axios.get(`http://localhost:5000/proposals`, {
          headers: { 'auth-token': token }
        });
        setProposals(res.data.filter(proposal => proposal.status === status));
      } catch (err) {
        console.error('Erro ao buscar propostas:', err.response?.data || err.message);
      }
    };

    fetchProposals();
  }, [token, status]);

  const handleStatusUpdate = async (proposalId, newStatus) => {
    try {
      await axios.put(`http://localhost:5000/proposals/${proposalId}/status`, { status: newStatus }, {
        headers: { 'auth-token': token }
      });
      // Atualize a lista de propostas localmente para refletir a mudança
      setProposals(proposals.map(proposal => 
        proposal.id === proposalId ? { ...proposal, status: newStatus } : proposal
      ));
    } catch (err) {
      console.error('Erro ao atualizar status da proposta:', err.response?.data || err.message);
    }
  };

  const handleFileUpload = async (proposalId, file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      await axios.post(`http://localhost:5000/proposals/${proposalId}/files`, formData, {
        headers: {
          'auth-token': token,
          'Content-Type': 'multipart/form-data'
        }
      });
      alert('Arquivo enviado com sucesso!');
    } catch (err) {
      console.error('Erro ao enviar arquivo:', err.response?.data || err.message);
    }
  };

  return (
    <div>
      <h1>Propostas {status}</h1>
      <ul>
        {proposals.map((proposal) => (
          <li key={proposal.id}>
            <h3>{proposal.title}</h3>
            <p>{proposal.description}</p>
            <a href={`/${proposal.file_path}`} download>Baixar Proposta</a>
            {status === 'offered' && user && !user.isAdmin && (
              <button onClick={() => handleStatusUpdate(proposal.id, 'accepted')}>Aceitar Proposta</button>
            )}
            {status === 'accepted' && user && user.isAdmin && (
              <button onClick={() => handleStatusUpdate(proposal.id, 'in-progress')}>Iniciar Finalização</button>
            )}
            {status === 'in-progress' && user && user.isAdmin && (
              <>
                <button onClick={() => handleStatusUpdate(proposal.id, 'completed')}>Finalizar</button>
                <input type="file" onChange={(e) => handleFileUpload(proposal.id, e.target.files[0])} />
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Proposals;
