import React from 'react';
import '../styles/About.css';
import Servidor from '../assets/images/servidor.jpg';
import { FaCheck } from 'react-icons/fa';

function About() {
  return (
    <section className="about">
      <div className="section-bg-video">
        <img src={Servidor} alt="GalleTTech" className="background-image" />
        <div className="overlay-content text-center">
          <h1>Sempre presente e eficiente</h1>
        </div>
      </div>
      <div className="container content">
        <div className="cards-container">
          <div className="card">
            <h3>Sobre a GalleTTech</h3>
            <p>
              A GalleTTech foi fundada em São Paulo, no bairro Pirituba, em 2024. Nossa equipe é composta por profissionais apaixonados por tecnologia da informação. Atuamos com foco na solução, otimizando o tempo de resposta e superando as expectativas dos nossos clientes.
            </p>
            <p>
              Se você está buscando um parceiro confiável para fornecer suporte e atendimento tecnológico para sua empresa, nós somos a escolha certa! Oferecemos serviços de suporte e atendimento em TI extremamente eficientes para manter sua empresa sempre atualizada e operando em seu melhor desempenho.
            </p>
            <p>
              Agradecimento especial ao "JARDC", as iniciais das pessoas que tornaram esse sonho possível.
            </p>
          </div>
          <div className="card">
            <h3>Terceirize sua T.I.</h3>
            <p>
              Nossa equipe de especialistas em TI está pronta para ajudar a solucionar problemas técnicos, gerenciar suas necessidades de infraestrutura e garantir que seus sistemas de TI operem sem interrupções. Além disso, oferecemos suporte ágil e eficiente para assegurar que sua empresa mantenha o máximo desempenho. Com nossos serviços, você terá a tranquilidade de saber que seus sistemas e infraestrutura estão em excelentes mãos.
            </p>
          </div>
        </div>
        <hr className="space" />
        <div className="cards-container">
          <div className="card-combined">
            <div className="left">
              <h3>Nosso Compromisso</h3>
              <p>
                Na GalleTTech, acreditamos que a tecnologia deve ser um facilitador para os negócios, não uma barreira. Estamos comprometidos em fornecer soluções que não apenas resolvam problemas, mas que também tragam inovação e eficiência para a sua empresa. Nosso objetivo é construir parcerias duradouras baseadas na confiança e no sucesso compartilhado.
              </p>
              <p>
                Entendemos que cada empresa é única, com desafios e necessidades específicas. Por isso, adotamos uma abordagem personalizada para garantir que nossas soluções sejam perfeitamente adequadas às suas exigências. Trabalhamos incansavelmente para garantir que nossos clientes tenham a melhor experiência possível com nossos serviços, desde o primeiro contato até a implementação final e além.
              </p>
            </div>
            <div className="right">
              <h3>Vantagens</h3>
              <ul className="fa-ul">
                <li><FaCheck className="fa-li" /> Redução de despesas</li>
                <li><FaCheck className="fa-li" /> Aumento da eficiência</li>
                <li><FaCheck className="fa-li" /> Foco nas prioridades principais</li>
                <li><FaCheck className="fa-li" /> Acesso a tecnologias de ponta</li>
                <li><FaCheck className="fa-li" /> Transparência no feedback</li>
                <li><FaCheck className="fa-li" /> Resolução rápida de problemas</li>
                <li><FaCheck className="fa-li" /> Melhor controle de demandas</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
