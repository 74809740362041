import React from 'react';
import { FaWhatsapp, FaEnvelope, FaClock } from 'react-icons/fa';
import '../styles/Contact.css';

function Contact() {
  return (
    <div className="contact">
      <div className="contact-section">
        <h2>Contato</h2>
        <p>Entre em contato conosco para saber mais sobre nossos serviços.</p>
        <div className="contact-content">
          <div className="contact-details">
            <div className="contact-item">
              <a href="https://wa.me/5511932205491?text=Ol%C3%A1%20gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20da%20GalleTTech" target="_blank" rel="noopener noreferrer">
                <FaWhatsapp size={32} />
                <span>(11) 93220-5491</span>
              </a>
            </div>
            <div className="contact-item">
              <FaEnvelope size={32} />
              <span>contato@gallettech.com.br</span>
            </div>
            <div className="contact-item">
              <FaClock size={32} />
              <span>Segunda a Sexta: 8h às 22:30h<br />Sábado: 10h às 14h</span>
            </div>
          </div>
          <div className="map">
            <iframe
              title="GalleTTech Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.8626937189884!2d-46.71594718442273!3d-23.50523308471209!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cefea80f4f7dfd%3A0xf643dc663447c55d!2sR.%20Silvestre%20de%20Almeida%20Lopes%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2002943-030%2C%20Brazil!5e0!3m2!1sen!2sbr!4v1622565005000!5m2!1sen!2sbr"
              width="100%"
              height="300"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
