import React, { useEffect } from 'react';
import { MdWeb, MdBuild } from 'react-icons/md';
import { FaCogs, FaCode, FaSearch, FaChalkboardTeacher } from 'react-icons/fa';
import { FaLightbulb, FaAward , FaGem , FaShieldAlt } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Importar o CSS do AOS
import '../styles/Home.css';

function Home() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duração da animação em milissegundos
      easing: 'ease-in-out', // Função de easing para a animação
    });
  }, []);

  return (
    <div className="home">
      <div className="intro">
        <div className="intro-content">
        <h1>Excelência em Soluções Tecnológicas Personalizadas</h1>
<p>
  Na GalleTTech, nosso compromisso é oferecer atendimento especializado e adaptado às necessidades específicas de cada negócio. Fundamentamos nossas práticas nos pilares de inovação, excelência e confiabilidade, garantindo serviços de alta qualidade que impulsionam o sucesso dos nossos clientes.
</p>

          <a href="#services" className="button">Saiba Mais</a>
        </div>
      </div>
      <div id="services" className="services-home">
        <div className="features">
          <div className="cards-feature">
          <div className="card-feature" data-aos="fade-up">
  <FaLightbulb size={48} />
  <h3>Inovação</h3>
  <p>
    Buscamos constantemente desenvolver soluções inovadoras, superando as expectativas do mercado e dos nossos clientes.
  </p>
</div>
<div className="card-feature" data-aos="fade-up">
  <FaAward size={48} />
  <h3>Excelência</h3>
  <p>
    Comprometidos com a qualidade, buscamos a melhoria contínua em todos os aspectos dos nossos serviços, dedicados às necessidades dos clientes.
  </p>
</div>
<div className="card-feature" data-aos="fade-up">
  <FaShieldAlt size={48} />
  <h3>Confiabilidade</h3>
  <p>
    Garantimos serviços seguros e funcionais, construindo uma relação de confiança com nossos clientes através de um trabalho íntegro e de qualidade.
  </p>

            </div>
          </div>
        </div>
        <div className="services-section-home">
          <h2>Serviços</h2>
          <p>Conheça os nossos tipos de serviços</p>
          <div className="cards-service">
            <div className="card-service" data-aos="fade-up">
              <MdWeb size={48} />
              <h3>Criação de Sites</h3>
              <p>
                Desenvolvemos sites modernos, responsivos e otimizados para motores de busca. Nossos serviços garantem que sua empresa se destaque no mundo digital, para o seu negócio atrair mais clientes.
              </p>
              <a href="/services">Saiba mais</a>
            </div>
            <div className="card-service" data-aos="fade-up">
              <FaCogs size={48} />
              <h3>Desenvolvimento de APIs</h3>
              <p>
                Desenvolvemos APIs modernas, seguras e eficientes. Nossos serviços integram e automatizam processos, facilitando o dia a dia do seu negócio com soluções inovadoras, aumentando a produtividade.
              </p>
              <a href="/services">Saiba mais</a>
            </div>
            <div className="card-service" data-aos="fade-up">
              <FaCode size={48} />
              <h3>Desenvolvimento de Software</h3>
              <p>
                Desenvolvemos softwares personalizados para atender às necessidades específicas do seu negócio. Nossos serviços impactam diretamente os números da organização, trazendo inovação e eficiência.
              </p>
              <a href="/services">Saiba mais</a>
            </div>
            <div className="card-service" data-aos="fade-up">
              <FaSearch size={48} />
              <h3>Otimização de SEO</h3>
              <p>
                Implementamos técnicas avançadas de SEO para melhorar o posicionamento do seu site nos motores de busca. Nossos serviços aumentam a visibilidade online da sua empresa, atraindo mais visitantes e potenciais clientes.
              </p>
              <a href="/services">Saiba mais</a>
            </div>
            <div className="card-service" data-aos="fade-up">
              <MdBuild size={48} />
              <h3>Manutenção de Sistemas</h3>
              <p>
                Oferecemos serviços de manutenção contínua para garantir que seus sistemas funcionem sem problemas. Nossos serviços incluem atualizações regulares e suporte técnico, mantendo seus softwares sempre eficientes e seguros.
              </p>
              <a href="/services">Saiba mais</a>
            </div>
            <div className="card-service" data-aos="fade-up">
              <FaChalkboardTeacher size={48} />
              <h3>Consultoria em TI</h3>
              <p>
                Prestamos consultoria em TI para ajudar sua empresa a adotar as melhores práticas e tecnologias. Nossos serviços identificam oportunidades de melhoria e implementam soluções que aumentam a produtividade e a competitividade do seu negócio.
              </p>
              <a href="/services">Saiba mais</a>
            </div>
          </div>
        </div>
      </div>
      
      </div>
    
  );
}

export default Home;
