import React from 'react';
import { FaFacebook, FaInstagram, FaYoutube, FaEnvelope } from 'react-icons/fa';
import '../styles/Footer.css';
import GoogleP from "../assets/images/google_partner.png";
import SSL from "../assets/images/certificadossl.png";
import GoogleP2 from "../assets/images/certificadogoogle.png"

function Footer() {
  return (
    <footer className="footer-light">
      <div className="container">
        <div className="row" style={{ textAlign: 'center' }}>
          <div className="col-lg-4">
            <div className="widget widgetLeft">
              <a href="/" className="logo-container1">GalleT<span>Tech</span></a>
              <div className="spacer-20"></div>
              <p>
                Desenvolvemos sites modernos, APIs seguras, softwares personalizados e técnicas avançadas de SEO para destacar sua empresa no mundo digital. Oferecemos manutenção contínua e consultoria em TI para aumentar a produtividade e competitividade do seu negócio.
              </p>
              <div>
                <img src={SSL} alt="SSL" className="partner-image" />
                <img src={GoogleP2} alt="Google" className="partner-image" />                
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="widget">
              <h5>Serviços</h5>
              <ul>
                <li><a href="./services" title="">Criação de Sites</a></li>
                <li><a href="./services" title="">Desenvolvimento de APIs</a></li>
                <li><a href="./services" title="">Desenvolvimento de Software</a></li>
                <li><a href="./services" title="">Otimização de SEO</a></li>
                <li><a href="./services" title="">Manutenção de Sistemas</a></li>
                <li><a href="./services" title="">Consultoria em TI</a></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="widget" style={{ display: 'inline-block' }}>
              <h5>Contato</h5>
              <address className="s1">
                <span><i className="fa fa-map-marker fa-lg"></i>Jardim Cidade Pirituba - São Paulo/SP</span>
                <span><i className="fa fa-phone fa-lg"></i><a href="tel:+5511932205491" style={{ cursor: 'pointer' }}>(11) 93220-5491</a></span>
                <span><i className="fa fa-envelope-o fa-lg"></i><a href="mailto:contato@gallettech.com.br">contato@gallettech.com.br</a></span>
              </address>
            </div>
          </div>
        </div>
        <div className="row mobileAlignCenter">
          <div className="col-md-6 sm-text-center mb-sm-30">
            <div className="mt10">© 2024 GalleTTech. Todos os direitos reservados. <a style={{ color: '#50E2F2' }} href="/politica.pdf" target="_blank">Política de privacidade.</a></div>
          </div>
          <div className="col-md-6 text-md-right text-sm-left">
            <div className="social-icons">
              <a href="https://www.linkedin.com/company/gallettech-solucoes-em-sistemas-e-tecnologia/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin fa-lg"></i></a>
              <a href="https://facebook.com/gallettech" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
              <a href="https://www.instagram.com/gallettech/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
              <a href="https://www.youtube.com/@gallettech" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
              <a href="mailto:contato@gallettech.com.br" target="_blank" rel="noopener noreferrer"><FaEnvelope /></a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
