import React, { useState, useEffect } from 'react';
import { MdWeb, MdBuild } from 'react-icons/md';
import { FaCogs, FaCode, FaSearch, FaChalkboardTeacher } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../styles/Services.css';

function Services() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
    });
  }, []);

  const [expanded, setExpanded] = useState({});

  const toggleExpand = (index) => {
    setExpanded(prevState => ({ ...prevState, [index]: !prevState[index] }));
  };

  return (
    <div className="services">
      <div className="services-section">
        <h2>Serviços</h2>
        <p>+30 Projetos Concluídos</p>
        <div className="cards-service">
          {servicesData.map((service, index) => (
            <div className="card-service" data-aos="fade-up" key={index}>
              {service.icon}
              <h3>{service.title}</h3>
              <p>{service.description}</p>
              {expanded[index] && (
                <div className="expanded-content">
                  <p>{service.detailedDescription}</p>
                  <ul>
                    {service.examples.map((example, exIndex) => (
                      <li key={exIndex}>{example}</li>
                    ))}
                  </ul>
                </div>
              )}
              <button onClick={() => toggleExpand(index)}>
                {expanded[index] ? 'Ver Menos' : 'Saiba Mais'}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const servicesData = [
  {
    icon: <MdWeb size={48} />,
    title: 'Criação de Sites',
    description: 'Desenvolvemos sites modernos, responsivos e otimizados para motores de busca.',
    detailedDescription: 'Nossos sites são projetados para oferecer uma experiência de usuário intuitiva e agradável, garantindo que sua empresa se destaque no mundo digital. Com um design atraente e funcionalidades avançadas, os sites que desenvolvemos atraem e retêm visitantes, convertendo-os em clientes fiéis.',
    examples: [
      'Sites corporativos personalizados para refletir a identidade da sua marca',
      'Lojas virtuais com integração de pagamento segura e fácil de usar',
      'Blogs e portfólios profissionais para destacar seu trabalho e atrair novos clientes',
    ],
  },
  {
    icon: <FaCogs size={48} />,
    title: 'Desenvolvimento de APIs',
    description: 'Desenvolvemos APIs modernas, seguras e eficientes.',
    detailedDescription: 'Nossas APIs são criadas para integrar e automatizar processos, facilitando a comunicação entre diferentes sistemas e melhorando a eficiência do seu negócio. Com nossa expertise, suas operações diárias serão mais ágeis e eficazes, permitindo um foco maior no crescimento e inovação.',
    examples: [
      'Integração de sistemas para melhorar a comunicação interna e externa',
      'Automação de processos repetitivos para economizar tempo e recursos',
      'APIs de pagamento para facilitar transações seguras e rápidas',
    ],
  },
  {
    icon: <FaCode size={48} />,
    title: 'Desenvolvimento de Software',
    description: 'Desenvolvemos softwares personalizados para atender às necessidades específicas do seu negócio.',
    detailedDescription: 'Oferecemos soluções de software sob medida que impactam diretamente a produtividade e os resultados da sua empresa. Com um enfoque em inovação e eficiência, nossos softwares ajudam a otimizar processos e melhorar a gestão, proporcionando uma vantagem competitiva significativa.',
    examples: [
      'Sistemas de gestão empresarial para controle e automação de processos',
      'Aplicativos móveis para engajar clientes e facilitar o acesso aos seus serviços',
      'Software de automação para aumentar a eficiência operacional e reduzir custos',
    ],
  },
  {
    icon: <FaSearch size={48} />,
    title: 'Otimização de SEO',
    description: 'Implementamos técnicas avançadas de SEO para melhorar o posicionamento do seu site.',
    detailedDescription: 'Com nossa otimização de SEO, seu site alcançará posições mais altas nos motores de busca, atraindo mais visitantes qualificados e aumentando a visibilidade da sua empresa. Utilizamos as melhores práticas para garantir que seu site seja encontrado pelo seu público-alvo.',
    examples: [
      'Análise de palavras-chave para identificar oportunidades de tráfego',
      'Otimização on-page para melhorar a relevância e usabilidade do seu site',
      'Link building para aumentar a autoridade do seu site nos motores de busca',
    ],
  },
  {
    icon: <MdBuild size={48} />,
    title: 'Manutenção de Sistemas',
    description: 'Oferecemos serviços de manutenção contínua para garantir que seus sistemas funcionem sem problemas.',
    detailedDescription: 'Nossos serviços de manutenção garantem que seus sistemas estejam sempre atualizados e seguros. Com atualizações regulares e suporte técnico especializado, você pode ter a tranquilidade de que seus softwares funcionarão de maneira eficiente e sem interrupções.',
    examples: [
      'Atualizações de software para incorporar as últimas melhorias e correções de segurança',
      'Correção de bugs para garantir a estabilidade e a performance dos seus sistemas',
      'Suporte técnico contínuo para resolver qualquer problema rapidamente',
    ],
  },
  {
    icon: <FaChalkboardTeacher size={48} />,
    title: 'Consultoria em TI',
    description: 'Prestamos consultoria em TI para ajudar sua empresa a adotar as melhores práticas e tecnologias.',
    detailedDescription: 'Nossos consultores em TI trabalham com você para identificar oportunidades de melhoria e implementar soluções tecnológicas que aumentam a produtividade e a competitividade do seu negócio. Com nossa orientação, você pode estar seguro de que está utilizando as melhores práticas da indústria.',
    examples: [
      'Avaliação de infraestrutura para identificar áreas de melhoria',
      'Planejamento estratégico para alinhar a tecnologia com os objetivos de negócios',
      'Treinamentos em TI para capacitar sua equipe e maximizar o uso das tecnologias disponíveis',
    ],
  },
];

export default Services;
