import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Proposals from './Proposals';
import ProposalForm from './ProposalForm';
import ClientForm from './ClientForm';

function DashboardRoutes() {
  return (
    <Routes>
      <Route path="offered" element={<Proposals status="offered" />} />
      <Route path="accepted" element={<Proposals status="accepted" />} />
      <Route path="in-progress" element={<Proposals status="in-progress" />} />
      <Route path="completed" element={<Proposals status="completed" />} />
      <Route path="create-proposal" element={<ProposalForm />} />
      <Route path="create-client" element={<ClientForm />} />
    </Routes>
  );
}

export default DashboardRoutes;
