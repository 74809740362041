import React, { useState } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';

function ClientForm() {
  const { token } = useOutletContext();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post('http://localhost:5000/clients', { name, email, password }, {
        headers: {
          'auth-token': token
        }
      });
      alert('Cliente cadastrado com sucesso!');
    } catch (err) {
      console.error('Erro ao cadastrar cliente:', err.response.data);
      alert('Erro ao cadastrar cliente.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Cadastrar Cliente</h2>
      <div>
        <label>Nome</label>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
      </div>
      <div>
        <label>Email</label>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
      </div>
      <div>
        <label>Senha</label>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
      </div>
      <button type="submit">Cadastrar</button>
    </form>
  );
}

export default ClientForm;
